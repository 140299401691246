<template>
    <div>
    <el-dialog :title="$t('All.签核页面')" :visible.sync="dialogTableVisible" :before-close="handleClose" append-to-body>
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="90px" label-position="top">
          <el-form-item :label="$t('All.签核')" prop="State">
            <el-radio-group v-model="formData.State" size="medium">
              <el-radio v-for="(item, index) in StatusOptions" :key="index" :label="item.value"
                :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('All.签核意见')">
            <el-input v-model="formData.Text" type="textarea" :placeholder="$t('All.请输入签核意见')"
              :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="Qianhecolse">{{$t('All.取消')}}</el-button>
          <el-button type="primary" @click="handelConfirm">{{$t('All.确定')}}</el-button>
        </div>
    </el-dialog>
    </div>
  </template>
<script>
export default {
  inheritAttrs: false,
  components: {},
  props: ['Qianhecolse', 'UserIpqcAdminhek', 'UserIpqcAdminboh'],
  data () {
    return {
      dialogTableVisible: true,
      formData: {
        Type: 0,
        State: '',
        Text: ''
      },
      rules: {
        State: [{
          required: true,
          message: this.$t('All.签核不能为空'),
          trigger: 'change'
        }]
      },
      StatusOptions: [{
        label: this.$t('All.同意'),
        value: 2
      }, {
        label: this.$t('All.驳回'),
        value: 1
      }]
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    handelConfirm () {
      this.$refs.elForm.validate(valid => {
        if (!valid) return this.$message.error(this.$t('All.请完善表单'))
        if (this.formData.State === 2) {
          this.UserIpqcAdminhek(this.formData)
        } else {
          this.UserIpqcAdminboh(this.formData)
        }
      })
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          this.Qianhecolse()
        })
        .catch(_ => {})
    }
  }
}

</script>
 <style lang="less" scoped>
  .el-dialog{
    max-width: 500px;
  }
  </style>
